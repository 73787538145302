import { API_URL, APP_DEBUG, APP_VERSION, MEDIA_BASE_URL } from './constants';
import { AdSlotPositionInjectTypeEnum } from './dto/recs.dto';
import type { PublicSettings } from './types';
import { uuidv4 } from './utils/uuid';

type TrendmdConfig = {
  element?: string;
  url?: string | null;
  journal_id?: string | null;
};

type AdSlotConfig = {
  adSlotId: string;
  selector: string;
  injectType?: AdSlotPositionInjectTypeEnum;
}

type TrendMDInjectorConfig = {
  apiEndpoint: string;
  polyfillsUrl: string;
  widgetUrl: string;
  version: string;
  debug: boolean;
}

export class TrendMDInjector {
  private readonly debug: boolean = false;
  private readonly version: string = '1.0.0';
  private readonly apiEndpoint: string;
  private readonly polyfillsUrl: string;
  private readonly widgetUrl: string;

  private installed = false;
  private adSetId = 'trendmd';
  private config: TrendmdConfig = {};

  private monitoredElements = new Set<Element>();

  constructor (private readonly window: Window, injectorConfig: TrendMDInjectorConfig) {
    this.version = injectorConfig.version;
    this.apiEndpoint = injectorConfig.apiEndpoint;
    this.polyfillsUrl = injectorConfig.polyfillsUrl;
    this.widgetUrl = injectorConfig.widgetUrl;
    this.debug = injectorConfig.debug;
  }

  public register (config: TrendmdConfig): void {
    this.log('Starting register');

    if (!config) {
      return;
    }

    this.config = { ...this.config, ...config };

    this.log('Registered config ', this.config);
  }

  public async run (): Promise<void> {
    if (this.installed) {
      this.log('Injector already installed');
      return;
    }

    this.installed = true;
    this.log('TrendMD: injector running');

    const sessionId = uuidv4();
    const scriptId = Math.random().toString(16).slice(2);

    // in the config was not registered, get it from the script tag
    if (Object.keys(this.config).length === 0) {
      const script: HTMLElement | null = this.window.document.querySelector('script[data-trendmdconfig]');

      if (script && script.hasAttribute('data-trendmdconfig')) {
        const trendMDConfig = (script.getAttribute('data-trendmdconfig') ?? '').trim();

        try {
          this.config = JSON.parse(trendMDConfig);
          this.log('Parsed config from script element');
        } catch (e) {
          try {
            this.config = JSON.parse(decodeURIComponent(trendMDConfig).replace(/'/g, '"'));
            this.log('Parsed config from script element');
          } catch (_e) {
            this.log('Invalid config. Using default config');
          }
        }
      }
    }

    // if the config is still not registered, we may be in an iframe
    if (Object.keys(this.config).length === 0) {
      // get the query string
      const queryString = this.window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams) {
        this.log('UrlParams', [ ...urlParams ]);

        this.config = { element: '#trendmd-suggestions' };

        // if we have the url param, set the url config property
        if (urlParams.has('url')) {
          this.config.url = urlParams.get('url');
          this.window.actualUrl = this.config.url;
        }

        // if we have the title param, set the page title
        if (urlParams.has('title')) {
          this.window.document.title = urlParams.get('title') ?? '';
        }

        // if we have the journal_id param, set the journal id
        if (urlParams.has('journal_id')) {
          this.config.journal_id = urlParams.get('journal_id');
        }
      }
    }

    // if the config is still not registered, set a default one
    if (Object.keys(this.config).length === 0 || typeof this.config !== 'object') {
      this.log('Setting default config');
      this.config = { element: '#trendmd-suggestions' };
    } else if (!this.config.element) {
      this.log('Setting default element');
      this.config.element = '#trendmd-suggestions';
    }

    if (!this.config.url) {
      this.config.url = this.window.location.href;
    }
    if (!this.config.journal_id) {
      this.config.journal_id = null;
    }

    this.log('Config ', this.config);

    const headers = new Headers();
    headers.set('X-Revops-Source-Url', this.config.url);
    headers.set('X-Revops-Source-Type', '2');
    headers.set('X-Revops-Version', this.version);
    headers.set('X-Revops-Session-Id', sessionId);
    headers.set('X-Revops-Reference-Id', this.config.journal_id ?? '');

    // fetch the adSet identifier
    await fetch(`${this.apiEndpoint}/ad-sets/identifier`, { method: 'GET', headers })
      .then((response) => {
        if (!response.ok) {
          this.log('Error fetching adSetId');
          throw new Error('Error fetching adSetId');
        }

        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        this.log('Identifier fetched', data);

        if (data.identifier) {
          this.adSetId = data.identifier;

          this.log('AdSetId', this.adSetId);
        }
      })
      .catch((error) => {
        this.log('Error fetching adSetId', error);
        throw new Error('Error fetching adSetId');
      });

    const adSlots: AdSlotConfig[] = [];
    let adSetSettings: PublicSettings = {} as PublicSettings;

    // fetch the adSet settings
    await fetch(`${this.apiEndpoint}/ad-sets/${this.adSetId}/settings`, { method: 'GET', headers })
      .then((response) => {
        if (!response.ok) {
          this.log('Error fetching settings');
          throw new Error('Error fetching settings');
        }

        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        adSetSettings = data;
        this.log('Settings fetched', data);

        if (!data.adSlots || data.adSlots.length === 0) {
          this.log('No adSlots found');
          return;
        }

        for (const adSlot of data.adSlots) {
          const selector = adSlot.settings?.positionSelector ?? this.config.element;
          const injectType = adSlot.settings?.positionInjectType;

          adSlots.push({
            adSlotId: adSlot.identifier,
            selector,
            injectType,
          });
        }

        this.log('AdSlots', adSlots);
      })
      .catch((error): void => {
        this.log('Error fetching settings', error);
        throw new Error('Error fetching settings');
      });

    // replace the initial adSlot elements with ins elements
    this.replaceAdSlotElements(adSlots, 'initial');

    // add a mutation observer to replace new adSlot elements with ins elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation): void => {
        if (mutation.addedNodes.length === 0) {
          return;
        }

        this.log('Mutation observed');
        this.replaceAdSlotElements(adSlots, 'mutation');
      });
    });

    observer.observe(this.window.document.body, { childList: true, subtree: true });

    // inject the polyfills
    const polyfillsLoader = this.window.document.createElement('script');
    polyfillsLoader.type = 'module';
    polyfillsLoader.src = this.polyfillsUrl;

    polyfillsLoader.onload = (): void => {
      this.log('TrendMD: polyfills loaded');

      this.window[ 'RevopsObject' ] = 'rjs';
      this.window[ 'rjs' ] = this.window[ 'rjs' ] || ((w) => function (...args: unknown[]): void {
        (w[ 'rjs' ].q = w[ 'rjs' ].q || []).push(args as never);
      })(this.window);

      this.window[ 'rjs' ]('config', {
        startTime: new Date().getTime(),
        datasetId: 'revops-suggestions',
        scriptId,
        id: this.adSetId,
        debug: this.debug,
        publicSettings: adSetSettings,
      });

      const widgetLoader = this.window.document.createElement('script');
      widgetLoader.async = true;
      widgetLoader.id = scriptId;
      widgetLoader.type = 'module';
      widgetLoader.src = this.widgetUrl;

      widgetLoader.onload = (): void => {
        this.log('Widget initialized for adSetId ' + this.adSetId);
      };

      this.window.document.head.appendChild(widgetLoader);

      this.log('Widget loader injected');
    };

    this.window.document.head.appendChild(polyfillsLoader);

    this.log('Polyfills loader injected');
  }

  private replaceAdSlotElements (adSlots: AdSlotConfig[], source: string): void {
    adSlots.forEach((adSlot) => {
      const adSlotElements = this.window.document.querySelectorAll(adSlot.selector);

      adSlotElements.forEach((adSlotElement) => {
        if (this.monitoredElements.has(adSlotElement)) {
          this.log('Element already processed');
          return;
        }

        if (!adSlotElement) {
          this.log(`Element with selector ${adSlot.selector} not found`);
          return;
        }

        this.monitoredElements.add(adSlotElement);

        this.log(`Rendering ${adSlot.adSlotId} element from source ${source} with injectionType ${adSlot.injectType}`);

        const insElement = this.window.document.createElement('ins');
        insElement.setAttribute('data-revops-suggestions', adSlot.adSlotId);
        insElement.setAttribute('observed', 'true');

        switch (adSlot.injectType) {
          case AdSlotPositionInjectTypeEnum.BEFORE_END:
            adSlotElement.appendChild(insElement);
            this.log(`Element processed by appending before end`);
            break;
          case AdSlotPositionInjectTypeEnum.AFTER_BEGIN:
            adSlotElement.prepend(insElement);
            this.log(`Element processed by prepending after begin`);
            break;
          case AdSlotPositionInjectTypeEnum.REPLACE:
            this.log(`Element processed by replacing`);
            adSlotElement.replaceWith(insElement);
            break;
          case AdSlotPositionInjectTypeEnum.NONE:
            adSlotElement.appendChild(insElement);
            this.log(`Element processed by appending child`);
            break;
        }
      });
    });
  }

  private log (...args: unknown[]): void {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log('[TrendMD]: ', ...args);
    }
  }
}

((w): void => {
  if (w.TrendMD) {
    return;
  }

  w.TrendMD = new TrendMDInjector(w, {
    apiEndpoint: `${API_URL}`,
    polyfillsUrl: `${MEDIA_BASE_URL}/polyfills.js?${APP_VERSION}`,
    widgetUrl: `${MEDIA_BASE_URL}/widget.js?${APP_VERSION}`,
    version: APP_VERSION || '1.0.0',
    debug: typeof APP_DEBUG === 'string' ? APP_DEBUG === 'true' : APP_DEBUG,
  });
  try {
    w.TrendMD.run();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('TrendMD: error running injector', e);
  }

  // window.addEventListener('load', async function() {
  //     await window.TrendMD.run();
  // });
})(window);
